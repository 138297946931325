<template>
  <v-container class="px-6 pl-10" style="margin: initial">
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-medium">
          New Prescription(s)
        </h3>
      </v-col>
      <v-col cols="12">
        <new-prescription @back="back" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewPrescription from '@/components/Prescriptions/PrescriptionItems/New'

export default {
  name: 'NewPrescriptionView',
  components: {
    NewPrescription
  },
  methods: {
    back () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
