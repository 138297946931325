<template>
  <div>Search Drug Prices</div>
</template>

<script>
export default {
  name: 'SearchPrices'
}
</script>

<style scoped>

</style>
