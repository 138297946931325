<template>
  <div v-if="!prescriptionsCreated">
    <gg-card v-if="!subStep">
      <gg-card-header>Upload a Picture of Your Prescription</gg-card-header>
      <gg-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/img/new-prescription.jpg')" />
          </v-col>
          <v-col cols="12" md="8">
            <p class="body-2 mb-3">
              We legally require a copy of your prescription before we can begin fulfilling the prescription.
              Please upload the full page prescription provided to you by your physician.
            </p>
            <validation-observer ref="validatorUpload">
              <div class="d-flex flex-column flex-md-row">
                <file-upload rules="required" name="upload">
                  Upload
                </file-upload>
                <file-upload class="ml-md-5 mt-5 mt-md-0" name="upload-additional">
                  Additional upload
                </file-upload>
              </div>
            </validation-observer>
          </v-col>
        </v-row>
      </gg-card-text>
      <v-card-actions class="justify-content-end">
        <sp-btn secondary @click="cancel">Back</sp-btn>
        <sp-btn @click="upload">Next</sp-btn>
      </v-card-actions>
    </gg-card>
    <gg-card v-else>
      <gg-card-header>One Final Step</gg-card-header>
      <gg-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
          </v-col>
          <v-col cols="12" md="8">
            <validation-observer ref="validator">
              Due to laws, you will be required to provide us with an original copy of the prescription(s).
              <validation-provider rules="required" v-slot="{ errors }">
                <v-select
                  v-model="copyOrigin"
                  :items="items"
                  dense
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </validation-observer>
          </v-col>
        </v-row>
      </gg-card-text>
      <v-card-actions class="justify-content-end">
        <sp-btn secondary @click="back">Back</sp-btn>
        <sp-btn @click="next">Next</sp-btn>
      </v-card-actions>
    </gg-card>
  </div>
  <uploaded-prescriptions v-else />
</template>

<script>
import { mapMutations } from 'vuex'
import FileUpload from '@/components/Common/FileUpload'
import UploadedPrescriptions from '@/components/Prescriptions/Info/UploadedPrescriptions'

export default {
  name: 'New',
  components: {
    FileUpload,
    UploadedPrescriptions
  },
  data: () => ({
    subStep: false,
    copyOrigin: '',
    prescriptionsCreated: false,
    items: [
      {
        text: 'I will mail the original prescription to SimplyPills',
        value: 'mail'
      },
      {
        text: 'Fax my prescription to doctor for co-sign',
        value: 'fax'
      }
    ]
  }),
  methods: {
    ...mapMutations({
      SET_PRESCRIPTION_COMPLETED: 'prescription/SET_PRESCRIPTION_COMPLETED'
    }),
    cancel () {
      this.$emit('back')
    },
    back () {
      this.subStep = false
      this.cancel()
    },
    async upload () {
      const valid = await this.$refs.validatorUpload.validate()
      if (!valid) {
        return
      }
      this.subStep = true
    },
    async next () {
      const valid = await this.$refs.validator.validate()
      if (!valid) {
        return
      }
      this.SET_PRESCRIPTION_COMPLETED(true)
      this.prescriptionsCreated = true
    }
  }
}
</script>

<style scoped>

</style>
