<template>
  <v-card>
    <v-card-text class="text-center py-6">
      Thank you, we’ve received your prescription and the pharmacy will begin working on your request.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UploadedPrescriptions'
}
</script>
