<template>
  <gg-card>
    <gg-card-header>Refill your Prescriptions</gg-card-header>
    <gg-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
        </v-col>
        <v-col cols="12" sm="8">
          <div v-if="pharmacy_items.length !== 0">
            <v-checkbox v-for="(item, i) in pharmacy_items"
                        :key="i"
                        :label="item.text"
                        :value="item.value"
                        v-model="prescriptions"
                        dense
                        hide-details
            />
          </div>
          <div v-else class="pt-5">
            No available prescriptions for refill.
          </div>
        </v-col>
      </v-row>
    </gg-card-text>
    <v-card-actions class="justify-content-end">
      <sp-btn secondary @click="available = !available">Toggle (temporary)</sp-btn>
      <sp-btn secondary @click="cancel">Back</sp-btn>
      <sp-btn @click="next">Continue</sp-btn>
    </v-card-actions>
  </gg-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Refill',
  data: () => ({
    available: true,
    prescriptions: []
  }),
  computed: {
    pharmacy_items () {
      if (this.available) {
        return [
          {
            text: 'Pharmacy 1',
            value: 'Pharmacy 1'
          },
          {
            text: 'Pharmacy 2',
            value: 'Pharmacy 2'
          },
          {
            text: 'Pharmacy 3',
            value: 'Pharmacy 3'
          },
          {
            text: 'Pharmacy 4',
            value: 'Pharmacy 4'
          },
          {
            text: 'Pharmacy 5',
            value: 'Pharmacy 5'
          },
          {
            text: 'Pharmacy 6',
            value: 'Pharmacy 6'
          },
          {
            text: 'Pharmacy 7',
            value: 'Pharmacy 7'
          }
        ]
      }

      return []
    }
  },
  methods: {
    ...mapMutations({
      SET_PRESCRIPTION_COMPLETED: 'prescription/SET_PRESCRIPTION_COMPLETED'
    }),
    cancel () {
      this.$router.push('/')
    },
    next () {
      this.SET_PRESCRIPTION_COMPLETED(true)
      this.$emit('next')
    }
  }
}
</script>

<style scoped>

</style>
