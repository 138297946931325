<template>
  <div>
    <gg-card v-if="!transferComplete">
      <gg-card-header>Let’s Transfer Your Prescription!</gg-card-header>
      <gg-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-img :src="require('@/assets/img/bg.jpg')"/>
          </v-col>
          <v-col cols="12" sm="8">
            <v-row>
              <v-col cols="12">
                <gg-card>
                  <gg-card-text>
                    <label class="d-block pb-1">
                      Find your existing Pharmacy.
                    </label>
                    <v-autocomplete v-model="model"
                                    :items="entries"
                                    :loading="pending"
                                    :search-input.sync="search"
                                    outlined
                                    hide-no-data
                                    item-text="text"
                                    item-value="Company_Name"
                                    prepend-icon="mdi-database-search"
                                    return-object
                                    @change="setSelected"
                    >
                      <template v-slot:item="{ item }">
                        <v-icon>mdi-map-marker</v-icon>
                        <b class="pr-1">{{ item.Company_Name }}</b>

                        <span>{{ item.Address_1 }}, {{ item.City }}</span>
                      </template>
                    </v-autocomplete>
                  </gg-card-text>
                  <v-divider></v-divider>
                  <gg-card v-for="(item, i) in selected" :key="i" class="mb-5">
                    <gg-card-header>
                      YOUR SELECTED PHARMACY
                      <sp-btn secondary class="float-right" @click="removeSelected(i)">
                        Remove
                      </sp-btn>
                    </gg-card-header>
                    <gg-card-text>
                      <div>
                        <b>
                          {{ item.Company_Name }}
                        </b>
                      </div>
                      <div class="pb-2">
                        {{ item.Accreditation_Number }}
                      </div>
                      <div>
                        {{ item.Address_1 }}, {{ item.City }}
                      </div>
                    </gg-card-text>
                    <v-card-actions class="d-flex flex-column">
                      <v-checkbox v-model="item.checked" label="Transfer all medications" class="w-100"/>

                      <v-textarea v-if="!item.checked" outlined
                                  label="Please list the medications you want us to transfer" class="w-100"/>
                    </v-card-actions>
                  </gg-card>
                </gg-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </gg-card-text>
      <v-card-actions class="justify-content-end">
        <sp-btn secondary @click="cancel">Back</sp-btn>
        <sp-btn v-if="selected" @click="next">Transfer</sp-btn>
      </v-card-actions>
    </gg-card>
    <gg-card v-else>
      <transferred-prescriptions />
    </gg-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import TransferredPrescriptions from '@/components/Prescriptions/Info/TransferredPrescriptions'

export default {
  name: 'Transfer',
  components: {
    TransferredPrescriptions
  },
  data: () => ({
    pending: false,
    pharmacy: '',
    selected: [],
    transferComplete: false,
    entries: [],
    model: null,
    search: null
  }),
  computed: {
    ...mapGetters({
      prescriptionsList: 'prescription/prescriptionsList'
    })
  },
  watch: {
    async search (val) {
      await this.getData(val)
    }
  },
  methods: {
    ...mapActions({
      findPrescriptions: 'prescription/findPrescriptions'
    }),
    ...mapMutations({
      SET_PRESCRIPTION_COMPLETED: 'prescription/SET_PRESCRIPTION_COMPLETED'
    }),
    async getData (val) {
      try {
        this.pending = true
        const data = await this.findPrescriptions(val)

        this.entries = data.map(item => {
          const tmp = Object.assign({}, item)
          tmp.text = `${item.Company_Name} - ${item.Address_1}, ${item.City}`

          return tmp
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.pending = false
      }
    },
    setSelected (val) {
      this.$set(val, 'checked', true)

      this.selected.push(val)
    },
    removeSelected (index) {
      this.selected.splice(index, 1)
    },
    cancel () {
      this.$router.push('/')
    },
    next () {
      this.SET_PRESCRIPTION_COMPLETED(true)
      this.transferComplete = true
    }
  }
}
</script>
