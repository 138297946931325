<template>
  <v-container class="px-6 pl-10" style="margin: initial">
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-medium">
          Search Drug Prices
        </h3>
      </v-col>
      <v-col cols="12">
        <search-prices />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchPrices from '@/components/Prescriptions/PrescriptionItems/SearchPrices'

export default {
  name: 'SearchPricesView',
  components: {
    SearchPrices
  }
}
</script>

<style scoped>

</style>
