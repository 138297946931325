<template>
  <v-container class="px-6 pl-10" style="margin: initial">
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-medium">
          Refill Prescription(s)
        </h3>
      </v-col>
      <v-col cols="12">
        <refill v-if="!transferred" @next="transferred = true" />
        <transferred-prescriptions v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Refill from '@/components/Prescriptions/PrescriptionItems/Refill'
import TransferredPrescriptions from '@/components/Prescriptions/Info/TransferredPrescriptions'

export default {
  name: 'RefillPrescriptionView',
  components: {
    TransferredPrescriptions,
    Refill
  },
  data () {
    return {
      transferred: false
    }
  }
}
</script>
