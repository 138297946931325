<template>
  <v-container class="px-6 pl-10" style="margin: initial">
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-medium">
          Transfer Your Prescription(s)
        </h3>
      </v-col>
      <v-col cols="12">
        <transfer />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Transfer from '@/components/Prescriptions/PrescriptionItems/Transfer'

export default {
  name: 'TransferRefillsView',
  components: {
    Transfer
  }
}
</script>

<style scoped>

</style>
