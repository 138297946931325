<template>
  <v-card>
    <v-card-text class="text-center py-6">
      Thank you, we’ve received the prescription transfer request and the pharmacy will
      begin working on your Prescription transfer.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TransferredPrescriptions'
}
</script>
